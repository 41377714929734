import React, { useContext } from 'react';
import './Recipe.scss';
import IngredientList from '../../components/Recipe/IngredientList';
import { RecipeContext } from '../../contents/Recipes/Recipes';
import Button from '../Common/Button/Button';

export default function Recipe(props) {
  const { handleRecipeDelete, handleRecipeSelect } = useContext(RecipeContext);
  const { id, name, cookTime, servings, instructions, ingredients } = props;

  return (
    <div className='container recipe'>
      <div className='recipe__name'>{name}</div>
      <div className='recipe__action'>
        <Button
          label='Edit'
          size='medium'
          onClick={() => handleRecipeSelect(id)}
          primary={true}
        ></Button>
        &nbsp;
        <Button
          label='Delete'
          size='medium'
          onClick={() => handleRecipeDelete(id)}
          primary={true}
        ></Button>
      </div>
      <div className='recipe__row'>
        <span className='recipe__label'>Cook Time: </span>
        <span className='recipe__value'>{cookTime}</span>
      </div>
      <div className='recipe__row'>
        <span className='recipe__label'>Servings: </span>
        <span className='recipe__value'>{servings}</span>
      </div>
      <div className='recipe__row'>
        <span className='recipe__label '>Instructions: </span>
        <div className='recipe__value recipe__value--indented recipe__instructions'>
          {instructions}
        </div>
      </div>
      <div className='recipe__row'>
        <span className='recipe__label'>Ingredients: </span>
        <div className='recipe__value recipe__value--indented'>
          <IngredientList ingredients={ingredients} />
        </div>
      </div>
    </div>
  );
}
