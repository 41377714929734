import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useAuth } from '../../../contexts/AuthContext';
import routes from '../../../../PageRouteList';
import Breadcrumb from '../../Breadcrumb/Breadcrumb';

export default function PrivateRoute({ component: Component, ...rest }) {
  const { currentUser } = useAuth();

  return (
    <Route
      {...rest}
      render={(props) => {
        const crumbs = routes
          .filter(({ path }) => props.match.path.includes(path))
          .map(({ path, ...rest }) => ({
            path: Object.keys(props.match.params).length
              ? Object.keys(props.match.params).reduce(
                  (path, param) =>
                    path.replace(`:${param}`, props.match.params[param]),
                  path
                )
              : path,
            ...rest,
          }));

        return currentUser && currentUser.emailVerified ? (
          <>
            <Breadcrumb crumbs={crumbs} />
            <Component {...props} />
          </>
        ) : currentUser && !currentUser.emailVerified ? (
          <Redirect to='/email-verification' />
        ) : (
          <Redirect to='/login' />
        );
      }}
    ></Route>
  );
}
