import React from 'react';
import stringResource from '../../resources/strings';
import PointOfIntrest from '../../components/PointOfIntrest/PointOfIntrest';
import MetaTags from 'react-meta-tags';
import './Home.scss';
import ReactGA from 'react-ga';
function Home() {
  ReactGA.event({
    category: 'page',
    action: 'user on homepage',
  });

  <MetaTags>
    <title>{stringResource.portfolio.home.pagetitle}</title>
    <meta
      name='description'
      content='A passionate software developer and musician.'
    />
  </MetaTags>;

  const developmentPOI = [
    {
      title: stringResource.portfolio.label.aboutme,
      content: stringResource.portfolio.home.aboutmedescr,
      displayLink: true,
    },
    {
      title: stringResource.portfolio.label.development,
      content: stringResource.portfolio.home.devdescr,
      displayLink: false,
    },
    {
      title: stringResource.portfolio.label.hobbies,
      content: stringResource.portfolio.home.hobbiesdescr,
      displayLink: true,
    },
  ];

  return (
    <div className='content'>
      <div className='home'>
        <div className='home__video'>
          <video
            autoPlay
            muted
            playsInline
            loop
            poster={process.env.PUBLIC_URL + '/poster.gif'}
          >
            <source
              src={process.env.PUBLIC_URL + '/videos/website.mp4'}
              type='video/mp4'
              controls
            ></source>
            Your browser does not support the <code>video</code> element.
          </video>
        </div>
        <div className='home__section'>
          <div className='home__section__introduction'>
            <PointOfIntrest props={developmentPOI} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
