import firebase from 'firebase';
import 'firebase/auth';

const firebaseApp = firebase.initializeApp({
  apiKey: 'AIzaSyDjQsgZuaKAOV8jT5jQ5EByQqiUj5BzAII',
  authDomain: 'ankit-mahendru.firebaseapp.com',
  projectId: 'ankit-mahendru',
  storageBucket: 'ankit-mahendru.appspot.com',
  messagingSenderId: '286524097987',
  appId: '1:286524097987:web:8df10a1254aaea6d3c6976',
  measurementId: 'G-9YJK6XDJCV',
});

const db = firebaseApp.firestore();

export const auth = firebaseApp.auth();
export default db;
