import React, { useRef, useState } from 'react';
import { useAuth } from '../../../contexts/AuthContext';
import { Link, useHistory } from 'react-router-dom';
import GoogleLogin from '../GoogleLogin/GoogleLogin';
import MetaTags from 'react-meta-tags';
import Button from '../../Common/Button/Button';
import Input from '../../Common/Input/Input';
import './Login.scss';

function Login() {
  const emailRef = useRef();
  const passwordRef = useRef();
  const { login } = useAuth();
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  async function handleSubmit(e) {
    e.preventDefault();

    try {
      setError('');
      setLoading(true);
      await login(emailRef.current.value, passwordRef.current.value);
      history.push('/dashboard');
    } catch {
      setError('Failed to sign in');
    }

    setLoading(false);
  }

  return (
    <div className='content login'>
      <MetaTags>
        <title>Login</title>
      </MetaTags>
      <card>
        <div className='login__logoContainer'>
          <div className='login__logo'></div>
        </div>

        <body>
          <h2 className='login__title'>Log In</h2>
          {error && <alert variant='danger'> {error}</alert>}
        </body>
        <form onSubmit={handleSubmit} className='login__form'>
          <group id='email'>
            <Input
              size='large'
              type='email'
              ref={emailRef}
              required
              placeholder='Email Address'
            />
          </group>
          <group id='password'>
            <Input
              size='large'
              type='password'
              ref={passwordRef}
              required
              placeholder='Password'
            />
          </group>
          <Button
            disabled={loading}
            label='Log In'
            size='medium'
            type='submit'
            primary={true}
          ></Button>
          <GoogleLogin />
        </form>
        <div className='login__forgotPassword'>
          <Link to='/forgot-password'> Forgot Password?</Link>
        </div>
        <div className='login__question'>Need an account?</div>
        <div className='login__signup'>
          <Link to='/signup'> Sign Up</Link>
        </div>
      </card>
    </div>
  );
}

export default Login;
