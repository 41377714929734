import imagesResource from './client/resources/images';
import stringResource from './client/resources/strings';

export default [
  {
    title: stringResource.portfolio.education.masters.title,
    where: stringResource.portfolio.education.masters.where,
    from: stringResource.portfolio.education.masters.from,
    to: stringResource.portfolio.education.masters.to,
    id: stringResource.portfolio.education.masters.id,
    imgsrc: imagesResource.education.masterspicture,
  },
  {
    title: stringResource.portfolio.education.bachelors.title,
    where: stringResource.portfolio.education.bachelors.where,
    from: stringResource.portfolio.education.bachelors.from,
    to: stringResource.portfolio.education.bachelors.to,
    id: stringResource.portfolio.education.bachelors.id,
    imgsrc: imagesResource.education.bachelorspicture,
  },
];
