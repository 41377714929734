import React from 'react';
import db from '../../firebase';
import firebase from 'firebase';
import Emoji from '../../components/Emoji/Emoji';

const Todo = ({ props }) => {
  return (
    <div
      className={`row todo__item ${
        props.isComplete ? 'todo__item--complete' : 'todo__item--todo'
      }`}
      onClick={() => {
        db.collection('todos').doc(props.id).update({
          isComplete: !props.isComplete,
          timestamp: firebase.firestore.FieldValue.serverTimestamp(),
        });
      }}
      key={props.id}
    >
      <span className='col todo__todo'>{props.todo}</span> &nbsp;
      <span className='col todo__status'>
        {props.isComplete ? (
          <Emoji label='greentick' symbol='✅' />
        ) : (
          <Emoji label='Hollow Red Circle' symbol='⭕' />
        )}
      </span>
      <span
        className='todo--delete'
        onClick={() => db.collection('todos').doc(props.id).delete()}
      >
        <Emoji label='bin' symbol='🗑️' />
      </span>
    </div>
  );
};

export default Todo;
