import React, { useContext } from 'react';
import Recipe from '../Recipe/Recipe';
import { RecipeContext } from '../../contents/Recipes/Recipes';
import Pdf from 'react-to-pdf';
import Button from '../Common/Button/Button';

const ref = React.createRef();

import './RecipeList.scss';

export default function RecipeList({ recipes, recipeCount }) {
  const { handleRecipeAdd } = useContext(RecipeContext);

  return (
    <div className='container recipe-list'>
      <div className='recipe-list__add-recipe-btn-conatiner'>
        <Button
          label='Add Recipe'
          size='medium'
          primary={true}
          onClick={handleRecipeAdd}
        ></Button>
        &nbsp;
        {recipeCount === 0 ? (
          ''
        ) : (
          <Pdf targetRef={ref} filename='my-recipes.pdf'>
            {({ toPdf }) => (
              <Button
                primary={false}
                label='Generate Pdf'
                size='medium'
                onClick={toPdf}
              ></Button>
            )}
          </Pdf>
        )}
        &nbsp;
      </div>
      <div className='recipe-list__count'>Total Recipes: {recipeCount}</div>
      <div className='recipe-list__content' ref={ref}>
        {recipes.map((recipe) => {
          return <Recipe {...recipe} key={recipe.id} />;
        })}
      </div>
    </div>
  );
}
