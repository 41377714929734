import React, { useRef, useState } from 'react';
import { useAuth } from '../../../contexts/AuthContext';
import { Link } from 'react-router-dom';
import MetaTags from 'react-meta-tags';
import Button from '../../Common/Button/Button';
import Input from '../../Common/Input/Input';
import './ForgotPassword.scss';

export default function ForgotPassword() {
  const emailRef = useRef();
  const { resetPassword } = useAuth();
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');

  async function handleSubmit(e) {
    e.preventDefault();

    try {
      setMessage('');
      setError('');
      setLoading(true);
      await resetPassword(emailRef.current.value);
      setMessage('Check your inbox for further instructions');
    } catch {
      setError('Failed to reset password');
    }

    setLoading(false);
  }

  return (
    <div className='content forgot'>
      <MetaTags>
        <title>Forgot Password</title>
      </MetaTags>
      <card>
        <div className='forgot__logoContainer'>
          <div className='forgot__logo'></div>
        </div>
        <body>
          <h2 className='forgot__title'>Forgot Password</h2>
          {error && <alert variant='danger'> {error}</alert>}
          {message && <alert variant='sucess'> {message}</alert>}
        </body>
        <form onSubmit={handleSubmit} className='forgot__form'>
          <group id='email'>
            <Input
              size='large'
              type='email'
              ref={emailRef}
              required
              placeholder='Email Address'
            />
          </group>
          <Button
            disabled={loading}
            label='Reset'
            size='medium'
            type='submit'
            primary={true}
          ></Button>
        </form>
        <div className='forgot__login'>
          <Link to='/login'> Login </Link>
        </div>
        <div className='forgot__question'>Need an account?</div>
        <div className='forgot__signup'>
          <Link to='/signup'> Sign Up</Link>
        </div>
      </card>
    </div>
  );
}
