import React from 'react';
import imagesResource from '../../resources/images';
import stringResource from '../../resources/strings';
import MetaTags from 'react-meta-tags';
import Post from '../../components/Post/Post';
import './Pictures.scss';

function Pictures() {
  return (
    <div className='content media'>
      <MetaTags>
        <title>{stringResource.portfolio.pictures.pagetitle}</title>
        <meta
          name='description'
          content={stringResource.portfolio.pictures.descr}
        />
      </MetaTags>
      <div className='media__row'>
        <div className='media__column'>
          <Post
            username={stringResource.portfolio.pictures.petusername}
            caption={stringResource.portfolio.pictures.d1.caption}
            imageurl={imagesResource.home.logo}
            profileimageurl={imagesResource.media.d1}
          />

          <Post
            username={stringResource.portfolio.pictures.petusername}
            caption={stringResource.portfolio.pictures.d2.caption}
            imageurl={imagesResource.home.logo}
            profileimageurl={imagesResource.media.d2}
          />

          <Post
            username={stringResource.portfolio.pictures.petusername}
            caption={stringResource.portfolio.pictures.d3.caption}
            imageurl={imagesResource.home.logo}
            profileimageurl={imagesResource.media.d3}
          />

          <Post
            username={stringResource.portfolio.pictures.petusername}
            caption={stringResource.portfolio.pictures.d4.caption}
            imageurl={imagesResource.home.logo}
            profileimageurl={imagesResource.media.d4}
          />

          <Post
            username={stringResource.portfolio.pictures.petusername}
            caption={stringResource.portfolio.pictures.d5.caption}
            imageurl={imagesResource.home.logo}
            profileimageurl={imagesResource.media.d5}
          />

          <Post
            username={stringResource.portfolio.pictures.petusername}
            caption={stringResource.portfolio.pictures.d6.caption}
            imageurl={imagesResource.home.logo}
            profileimageurl={imagesResource.media.d6}
          />

          <Post
            username={stringResource.portfolio.pictures.petusername}
            caption={stringResource.portfolio.pictures.d7.caption}
            imageurl={imagesResource.home.logo}
            profileimageurl={imagesResource.media.d7}
          />

          <Post
            username={stringResource.portfolio.pictures.petusername}
            caption={stringResource.portfolio.pictures.d29.caption}
            imageurl={imagesResource.home.logo}
            profileimageurl={imagesResource.media.d29}
          />
        </div>
        <div className='media__column'>
          <Post
            username={stringResource.portfolio.pictures.petusername}
            caption={stringResource.portfolio.pictures.d8.caption}
            imageurl={imagesResource.home.logo}
            profileimageurl={imagesResource.media.d8}
          />

          <Post
            username={stringResource.portfolio.pictures.petusername}
            caption={stringResource.portfolio.pictures.d9.caption}
            imageurl={imagesResource.home.logo}
            profileimageurl={imagesResource.media.d9}
          />

          <Post
            username={stringResource.portfolio.pictures.petusername}
            caption={stringResource.portfolio.pictures.d10.caption}
            imageurl={imagesResource.home.logo}
            profileimageurl={imagesResource.media.d10}
          />

          <Post
            username={stringResource.portfolio.pictures.petusername}
            caption={stringResource.portfolio.pictures.d11.caption}
            imageurl={imagesResource.home.logo}
            profileimageurl={imagesResource.media.d11}
          />

          <Post
            username={stringResource.portfolio.pictures.petusername}
            caption={stringResource.portfolio.pictures.d12.caption}
            imageurl={imagesResource.home.logo}
            profileimageurl={imagesResource.media.d12}
          />

          <Post
            username={stringResource.portfolio.pictures.petusername}
            caption={stringResource.portfolio.pictures.d13.caption}
            imageurl={imagesResource.home.logo}
            profileimageurl={imagesResource.media.d13}
          />

          <Post
            username={stringResource.portfolio.pictures.petusername}
            caption={stringResource.portfolio.pictures.d14.caption}
            imageurl={imagesResource.home.logo}
            profileimageurl={imagesResource.media.d14}
          />

          <Post
            username={stringResource.portfolio.pictures.petusername}
            caption={stringResource.portfolio.pictures.d30.caption}
            imageurl={imagesResource.home.logo}
            profileimageurl={imagesResource.media.d30}
          />
        </div>
        <div className='media__column'>
          <Post
            username={stringResource.portfolio.pictures.petusername}
            caption={stringResource.portfolio.pictures.d15.caption}
            imageurl={imagesResource.home.logo}
            profileimageurl={imagesResource.media.d15}
          />

          <Post
            username={stringResource.portfolio.pictures.petusername}
            caption={stringResource.portfolio.pictures.d16.caption}
            imageurl={imagesResource.home.logo}
            profileimageurl={imagesResource.media.d16}
          />

          <Post
            username={stringResource.portfolio.pictures.petusername}
            caption={stringResource.portfolio.pictures.d17.caption}
            imageurl={imagesResource.home.logo}
            profileimageurl={imagesResource.media.d17}
          />

          <Post
            username={stringResource.portfolio.pictures.petusername}
            caption={stringResource.portfolio.pictures.d18.caption}
            imageurl={imagesResource.home.logo}
            profileimageurl={imagesResource.media.d18}
          />

          <Post
            username={stringResource.portfolio.pictures.petusername}
            caption={stringResource.portfolio.pictures.d19.caption}
            imageurl={imagesResource.home.logo}
            profileimageurl={imagesResource.media.d19}
          />

          <Post
            username={stringResource.portfolio.pictures.petusername}
            caption={stringResource.portfolio.pictures.d20.caption}
            imageurl={imagesResource.home.logo}
            profileimageurl={imagesResource.media.d20}
          />

          <Post
            username={stringResource.portfolio.pictures.petusername}
            caption={stringResource.portfolio.pictures.d21.caption}
            imageurl={imagesResource.home.logo}
            profileimageurl={imagesResource.media.d21}
          />

          <Post
            username={stringResource.portfolio.pictures.petusername}
            caption={stringResource.portfolio.pictures.d31.caption}
            imageurl={imagesResource.home.logo}
            profileimageurl={imagesResource.media.d31}
          />
        </div>
        <div className='media__column'>
          <Post
            username={stringResource.portfolio.pictures.petusername}
            caption={stringResource.portfolio.pictures.d22.caption}
            imageurl={imagesResource.home.logo}
            profileimageurl={imagesResource.media.d22}
          />

          <Post
            username={stringResource.portfolio.pictures.petusername}
            caption={stringResource.portfolio.pictures.d23.caption}
            imageurl={imagesResource.home.logo}
            profileimageurl={imagesResource.media.d23}
          />

          <Post
            username={stringResource.portfolio.pictures.petusername}
            caption={stringResource.portfolio.pictures.d24.caption}
            imageurl={imagesResource.home.logo}
            profileimageurl={imagesResource.media.d24}
          />

          <Post
            username={stringResource.portfolio.pictures.petusername}
            caption={stringResource.portfolio.pictures.d25.caption}
            imageurl={imagesResource.home.logo}
            profileimageurl={imagesResource.media.d25}
          />

          <Post
            username={stringResource.portfolio.pictures.petusername}
            caption={stringResource.portfolio.pictures.d26.caption}
            imageurl={imagesResource.home.logo}
            profileimageurl={imagesResource.media.d26}
          />

          <Post
            username={stringResource.portfolio.pictures.petusername}
            caption={stringResource.portfolio.pictures.d27.caption}
            imageurl={imagesResource.home.logo}
            profileimageurl={imagesResource.media.d27}
          />

          <Post
            username={stringResource.portfolio.pictures.petusername}
            caption={stringResource.portfolio.pictures.d28.caption}
            imageurl={imagesResource.home.logo}
            profileimageurl={imagesResource.media.d28}
          />

          <Post
            username={stringResource.portfolio.pictures.petusername}
            caption={stringResource.portfolio.pictures.d32.caption}
            imageurl={imagesResource.home.logo}
            profileimageurl={imagesResource.media.d32}
          />
        </div>
      </div>
    </div>
  );
}

export default Pictures;
