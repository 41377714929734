import imagesResource from './client/resources/images';
import stringResource from './client/resources/strings';

export default [
  {
    id: stringResource.portfolio.skills.job1.id,
    title: stringResource.portfolio.skills.job1.title,
    where: stringResource.portfolio.skills.job1.where,
    from: stringResource.portfolio.skills.job1.from,
    to: stringResource.portfolio.skills.job1.to,
    details: stringResource.portfolio.skills.job1.details,
    website: stringResource.portfolio.skills.job1.website,
    techUsed: stringResource.portfolio.skills.job1.techUsed,
    imgsrc: imagesResource.skills.spaarkspicture,
  },
  {
    id: stringResource.portfolio.skills.job2.id,
    title: stringResource.portfolio.skills.job2.title,
    where: stringResource.portfolio.skills.job2.where,
    from: stringResource.portfolio.skills.job2.from,
    to: stringResource.portfolio.skills.job2.to,
    details: stringResource.portfolio.skills.job2.details,
    website: stringResource.portfolio.skills.job2.website,
    techUsed: stringResource.portfolio.skills.job2.techUsed,
    imgsrc: imagesResource.skills.sqapicture,
  },
  {
    id: stringResource.portfolio.skills.job3.id,
    title: stringResource.portfolio.skills.job3.title,
    where: stringResource.portfolio.skills.job3.where,
    from: stringResource.portfolio.skills.job3.from,
    to: stringResource.portfolio.skills.job3.to,
    details: stringResource.portfolio.skills.job3.details,
    website: stringResource.portfolio.skills.job3.website,
    techUsed: stringResource.portfolio.skills.job3.techUsed,
    imgsrc: imagesResource.skills.ceridianpicture,
  },
  {
    id: stringResource.portfolio.skills.job4.id,
    title: stringResource.portfolio.skills.job4.title,
    where: stringResource.portfolio.skills.job4.where,
    from: stringResource.portfolio.skills.job4.from,
    to: stringResource.portfolio.skills.job4.to,
    details: stringResource.portfolio.skills.job4.details,
    website: stringResource.portfolio.skills.job4.website,
    techUsed: stringResource.portfolio.skills.job4.techUsed,
    imgsrc: imagesResource.skills.cctpicture,
  },

  {
    id: stringResource.portfolio.skills.job5.id,
    title: stringResource.portfolio.skills.job5.title,
    where: stringResource.portfolio.skills.job5.where,
    from: stringResource.portfolio.skills.job5.from,
    to: stringResource.portfolio.skills.job5.to,
    details: stringResource.portfolio.skills.job5.details,
    website: stringResource.portfolio.skills.job5.website,
    techUsed: stringResource.portfolio.skills.job5.techUsed,
    imgsrc: imagesResource.skills.nablepicture,
  },
];
