import React from 'react';
import './DisplayCard.scss';

function Card({ props }) {
  let hyphen = '';
  if (props.to) {
    hyphen = '-';
  }
  return (
    <>
      {props.map((academic) => {
        return (
          <ul className='displaycard__details' key={academic.id}>
            <li className='displaycard__item'>
              <b>{academic.title}</b>
            </li>
            <li className='displaycard__item'>{academic.where}</li>
            <li className='displaycard__item'>
              {academic.from} {hyphen} {academic.to}
            </li>
            <li className='displaycard__item'>
              <img
                src={academic.imgsrc}
                alt=''
                className='displaycard__picture img-fluid'
              ></img>
            </li>
          </ul>
        );
      })}
    </>
  );
}

export default Card;
