import React from 'react';
import firebase from 'firebase';
import { useHistory } from 'react-router-dom';
import './GoogleLogin.scss';

function GoogleLogin() {
  const history = useHistory();

  async function handleSubmit(event) {
    event.preventDefault();
    var provider = new firebase.auth.GoogleAuthProvider();
    await firebase.auth().signInWithPopup(provider);
    history.push('/dashboard');
  }

  return (
    <div className='googleLogin'>
      <div className='googleLogin__body'>
        <a onClick={handleSubmit}>&nbsp; Login with Google</a>
      </div>
    </div>
  );
}

export default GoogleLogin;
