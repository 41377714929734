import React, { useState, useEffect } from 'react';
import RecipeList from '../../components/Recipe/RecipeList';
import { useAuth } from '../../contexts/AuthContext';
import RecipeEdit from '../../components/Recipe/RecipeEdit';
import db from '../../firebase';
import firebase from 'firebase';
import stringResource from '../../resources/strings';
import './Recipes.scss';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import MetaTags from 'react-meta-tags';

toast.configure();

export const RecipeContext = React.createContext();

export default function Recipes() {
  const { currentUser } = useAuth();
  const [recipeCount, setRecipeCount] = useState(0);
  const [recipes, setRecipes] = useState([]);
  const [selectedRecipeId, setSelectedRecipeId] = useState();
  const selectedRecipe = recipes.find(
    (recipe) => recipe.id === selectedRecipeId
  );

  const recipeContextValue = {
    handleRecipeAdd,
    handleRecipeDelete,
    handleRecipeUpdate,
    handleRecipeSelect,
    handleRecipeChange,
  };

  useEffect(() => {
    document.title = stringResource.portfolio.label.recipes;

    db.collection('recipes')
      .where('userId', '==', currentUser.uid)
      .orderBy('timestamp', 'desc')
      .onSnapshot((snapshot) => {
        setRecipeCount(snapshot.docs.length);
        setRecipes(
          snapshot.docs.map((recipe) => ({
            id: recipe.id,
            cookTime: recipe.data().cookTime,
            instructions: recipe.data().instructions.replaceAll('\\n', '\n'),
            name: recipe.data().name,
            servings: recipe.data().servings,
            timestamp: recipe.data().timestamp,
            userId: recipe.data().userId,
            ingredients: recipe.data().ingredients,
          }))
        );
      });
  }, [currentUser.uid]);

  function handleRecipeAdd() {
    db.collection('recipes').add({
      name: 'New Recipe!',
      servings: 1,
      cookTime: '',
      instructions: '',
      timestamp: firebase.firestore.FieldValue.serverTimestamp(),
      userId: currentUser.uid,
      ingredients: [
        {
          amount: '',
          name: '',
        },
      ],
    });
  }

  function handleRecipeDelete(id) {
    if (selectedRecipeId != null && selectedRecipeId === id) {
      setSelectedRecipeId(undefined);
    }

    db.collection('recipes').doc(id).delete();
  }

  function handleRecipeUpdate(recipe) {
    console.log(recipe.id);
    db.collection('recipes').doc(recipe.id).update({
      id: recipe.id,
      cookTime: recipe.cookTime,
      instructions: recipe.instructions,
      name: recipe.name,
      servings: recipe.servings,
      timestamp: firebase.firestore.FieldValue.serverTimestamp(),
      userId: recipe.userId,
      ingredients: recipe.ingredients,
    });
  }

  function handleRecipeSelect(id) {
    setSelectedRecipeId(id);
  }

  function handleRecipeChange(id, recipe) {
    const newRecipes = [...recipes];
    const index = newRecipes.findIndex((r) => r.id === id);
    newRecipes[index] = recipe;
    setRecipes(newRecipes);
    handleRecipeUpdate(newRecipes[index]);
  }

  return (
    <RecipeContext.Provider value={recipeContextValue}>
      <MetaTags>
        <title>Recipes</title>
      </MetaTags>
      <div className='content-row recipes'>
        <RecipeList
          recipes={recipes}
          recipeCount={recipeCount}
          handleRecipeAdd={handleRecipeAdd}
        />
        {selectedRecipe && <RecipeEdit recipe={selectedRecipe} />}
      </div>
    </RecipeContext.Provider>
  );
}
