import React, { useContext } from 'react';
import RecipeIngredientEdit from './RecipeIngredientEdit';
import { RecipeContext } from '../../contents/Recipes/Recipes';
import Button from '../Common/Button/Button';
import Input from '../Common/Input/Input';
import Textarea from '../Common/Textarea/Textarea';

import './RecipeEdit.scss';

export default function RecipeEdit({ recipe }) {
  const { handleRecipeChange, handleRecipeSelect } = useContext(RecipeContext);

  function handleChange(changes) {
    handleRecipeChange(recipe.id, { ...recipe, ...changes });
  }

  function handleIngredientChange(id, ingredient) {
    const newIngredients = [...recipe.ingredients];
    const index = newIngredients.findIndex((i) => i.id === id);
    newIngredients[index] = ingredient;
    handleChange({ ingredients: newIngredients });
  }

  function handleIngredientAdd() {
    const newIngredient = {
      id: ID(),
      name: '',
      amount: '',
    };
    handleChange({ ingredients: [...recipe.ingredients, newIngredient] });
  }

  function handleIngredientDelete(id) {
    handleChange({
      ingredients: [...recipe.ingredients.filter((i) => i.id !== id)],
    });
  }

  function ID() {
    // Math.random should be unique because of its seeding algorithm.
    // Convert it to base 36 (numbers + letters), and grab the first 9 characters
    // after the decimal.
    return '_' + Math.random().toString(36).substr(2, 9);
  }

  return (
    <div className='recipe-edit'>
      <div className='recipe-edit__remove-button-container'>
        <Button
          onClick={() => handleRecipeSelect(undefined)}
          primary={false}
          size='small'
          label='X'
        >
          &times;
        </Button>
      </div>
      <div className='recipe-edit__details-grid'>
        <label htmlFor='name' className='recipe-edit__label'>
          Name
        </label>
        <Input
          size='large'
          type='text'
          name='name'
          id='name'
          value={recipe.name}
          onInput={(e) => handleChange({ name: e.target.value })}
        />

        <label htmlFor='cookTime' className='recipe-edit__label'>
          Cook Time
        </label>
        <Input
          size='large'
          type='text'
          name='cookTime'
          id='cookTime'
          value={recipe.cookTime}
          onInput={(e) => handleChange({ cookTime: e.target.value })}
        />

        <label htmlFor='servings' className='recipe-edit__label'>
          Servings
        </label>
        <Input
          size='large'
          type='number'
          min='1'
          name='servings'
          id='servings'
          value={recipe.servings}
          onInput={(e) =>
            handleChange({ servings: parseInt(e.target.value) || '' })
          }
        />

        <label htmlFor='instructions' className='recipe-edit__label'>
          Instructions
        </label>
        <Textarea
          size='large'
          name='instructions'
          id='instructions'
          className='recipe-edit__input form-control'
          value={recipe.instructions}
          onInput={(e) => handleChange({ instructions: e.target.value })}
        />
      </div>
      <br />
      <label className='recipe-edit__label'>Ingredients</label>
      <div className='recipe-edit__ingredient-grid'>
        <div>Name</div>
        <div>Amount</div>
        <div></div>
        {recipe.ingredients.map((ingredient, key) => (
          <RecipeIngredientEdit
            key={key}
            handleIngredientChange={handleIngredientChange}
            handleIngredientDelete={handleIngredientDelete}
            ingredient={ingredient}
          />
        ))}
      </div>
      <div className='recipe-edit__add-ingredient-btn-container'>
        <Button
          label='Add Ingreditent'
          size='medium'
          onClick={() => handleIngredientAdd()}
          primary={true}
        ></Button>
      </div>
    </div>
  );
}
