export default [
  {
    id: 1,
    name: 'Home',
    tolink: '/',
  },
  {
    id: 2,
    name: 'Skills',
    tolink: '/skills',
  },
  {
    id: 3,
    name: 'Pictures',
    tolink: '/pictures',
  },
];
