import React from 'react';
import { UIDConsumer } from 'react-uid';
import imageResource from '../../resources/images';
import './DevIcons.scss';

function DevIcons({ props, className }) {
  const html = 'HTML';
  const css = 'CSS';
  const reactjs = 'ReactJS';
  const csharp = 'C#.Net';
  const sql = 'SQL';
  const mvc = 'ASP.NET MVC';
  const mvccf = 'ASP.NET MVC Code First';
  const ts = 'Typescript';
  const angular = 'Angular';
  const git = 'GIT';
  const dojo = 'Dojo';
  const javascript = 'Javascript';
  const jquery = 'JQuery';
  const vscode = 'Visual Studio Code';
  const core = 'C#.Net Core';
  const java = 'Java';
  const sass = 'Sass';
  const subversion = 'Subversion';
  const ionic = 'Ionic';
  const tfs = 'TFS';
  const firebase = 'FireBase';
  const stripe = 'Stripe';
  const netlify = 'Netlify';
  const visualstudio = 'Visual Studio';

  return (
    <div className={`${className}`}>
      <div className={`${className}__icons`}>
        <UIDConsumer>
          {(_, uid) =>
            props.map((item) => {
              {
                {
                  return (
                    <div
                      className={`${className}__icons__item`}
                      key={uid(item)}
                    >
                      {item.trim() === html ? (
                        <>
                          <img
                            src={imageResource.icons.html}
                            alt='htmlIcon'
                          ></img>
                          <div className={`${className}__icons__item__name`}>
                            {item}
                          </div>
                        </>
                      ) : item.trim() === css ? (
                        <>
                          <img
                            src={imageResource.icons.css}
                            alt='csslIcon'
                          ></img>
                          <div className={`${className}__icons__item__name`}>
                            {item}
                          </div>
                        </>
                      ) : item.trim() === reactjs ? (
                        <>
                          <img
                            src={imageResource.icons.react}
                            alt='reactIcon'
                          ></img>
                          <div className={`${className}__icons__item__name`}>
                            {item}
                          </div>
                        </>
                      ) : item.trim() === csharp ? (
                        <>
                          <img
                            src={imageResource.icons.csharp}
                            alt='csharpIcon'
                          ></img>
                          <div className={`${className}__icons__item__name`}>
                            {item}
                          </div>
                        </>
                      ) : item.trim() === sql ? (
                        <>
                          <img
                            src={imageResource.icons.sql}
                            alt='sqlIcon'
                          ></img>
                          <div className={`${className}__icons__item__name`}>
                            {item}
                          </div>
                        </>
                      ) : item.trim() === mvc ? (
                        <>
                          <img
                            src={imageResource.icons.mvc}
                            alt='mvcIcon'
                          ></img>
                          <div className={`${className}__icons__item__name`}>
                            {item}
                          </div>
                        </>
                      ) : item.trim() === ts ? (
                        <>
                          <img src={imageResource.icons.ts} alt='tsIcon'></img>
                          <div className={`${className}__icons__item__name`}>
                            {item}
                          </div>
                        </>
                      ) : item.trim() === angular ? (
                        <>
                          <img
                            src={imageResource.icons.angular}
                            alt='angularIcon'
                          ></img>
                          <div className={`${className}__icons__item__name`}>
                            {item}
                          </div>
                        </>
                      ) : item.trim() === git ? (
                        <>
                          <img
                            src={imageResource.icons.git}
                            alt='gitIcon'
                          ></img>
                          <div className={`${className}__icons__item__name`}>
                            {item}
                          </div>
                        </>
                      ) : item.trim() === dojo ? (
                        <>
                          <img
                            src={imageResource.icons.dojo}
                            alt='dojoIcon'
                          ></img>
                          <div className={`${className}__icons__item__name`}>
                            {item}
                          </div>
                        </>
                      ) : item.trim() === javascript ? (
                        <>
                          <img
                            src={imageResource.icons.javascript}
                            alt='javascriptIcon'
                          ></img>
                          <div className={`${className}__icons__item__name`}>
                            {item}
                          </div>
                        </>
                      ) : item.trim() === jquery ? (
                        <>
                          <img
                            src={imageResource.icons.jquery}
                            alt='jqueryIcon'
                          ></img>
                          <div className={`${className}__icons__item__name`}>
                            {item}
                          </div>
                        </>
                      ) : item.trim() === vscode ? (
                        <>
                          <img
                            src={imageResource.icons.vscode}
                            alt='vscodeIcon'
                          ></img>
                          <div className={`${className}__icons__item__name`}>
                            {item}
                          </div>
                        </>
                      ) : item.trim() === core ? (
                        <>
                          <img
                            src={imageResource.icons.core}
                            alt='coreIcon'
                          ></img>
                          <div className={`${className}__icons__item__name`}>
                            {item}
                          </div>
                        </>
                      ) : item.trim() === java ? (
                        <>
                          <img
                            src={imageResource.icons.java}
                            alt='javaIcon'
                          ></img>
                          <div className={`${className}__icons__item__name`}>
                            {item}
                          </div>
                        </>
                      ) : item.trim() === sass ? (
                        <>
                          <img
                            src={imageResource.icons.sass}
                            alt='sassIcon'
                          ></img>
                          <div className={`${className}__icons__item__name`}>
                            {item}
                          </div>
                        </>
                      ) : item.trim() === subversion ? (
                        <>
                          <img
                            src={imageResource.icons.subversion}
                            alt='subversionIcon'
                          ></img>
                          <div className={`${className}__icons__item__name`}>
                            {item}
                          </div>
                        </>
                      ) : item.trim() === mvccf ? (
                        <>
                          <img
                            src={imageResource.icons.mvc}
                            alt='mvcCodeFirstIcon'
                          ></img>
                          <div className={`${className}__icons__item__name`}>
                            {item}
                          </div>
                        </>
                      ) : item.trim() === ionic ? (
                        <>
                          <img
                            src={imageResource.icons.ionic}
                            alt='ionicIcon'
                          ></img>
                          <div className={`${className}__icons__item__name`}>
                            {item}
                          </div>
                        </>
                      ) : item.trim() === tfs ? (
                        <>
                          <img
                            src={imageResource.icons.tfs}
                            alt='tfsIcon'
                          ></img>
                          <div className={`${className}__icons__item__name`}>
                            {item}
                          </div>
                        </>
                      ) : item.trim() === firebase ? (
                        <>
                          <img
                            src={imageResource.icons.firebase}
                            alt='firebaseFirstIcon'
                          ></img>
                          <div className={`${className}__icons__item__name`}>
                            {item}
                          </div>
                        </>
                      ) : item.trim() === stripe ? (
                        <>
                          <img
                            src={imageResource.icons.stripe}
                            alt='stripeFirstIcon'
                          ></img>
                          <div className={`${className}__icons__item__name`}>
                            {item}
                          </div>
                        </>
                      ) : item.trim() === netlify ? (
                        <>
                          <img
                            src={imageResource.icons.netlify}
                            alt='netlifyFirstIcon'
                          ></img>
                          <div className={`${className}__icons__item__name`}>
                            {item}
                          </div>
                        </>
                      ) : item.trim() === visualstudio ? (
                        <>
                          <img
                            src={imageResource.icons.visualstudio}
                            alt='visualstudioIcon'
                          ></img>
                          <div className={`${className}__icons__item__name`}>
                            {item}
                          </div>
                        </>
                      ) : undefined}
                    </div>
                  );
                }
              }
            })
          }
        </UIDConsumer>
      </div>
    </div>
  );
}

export default DevIcons;
