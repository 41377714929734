import React from 'react';
import { Link } from 'react-router-dom';
import stringResource from '../../resources/strings';
import { faLink, faMusic } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './PointOfIntrest.scss';

export default function PointOfIntrest({ props }) {
  return (
    <section className='poi'>
      {props.map((poi, key) => {
        return (
          <div className={'poi__item'} key={key}>
            <div className={'poi__item__title'}>{poi.title}</div>
            <div className={'poi__item__content'}>{poi.content}</div>
            <>
              {poi.displayLink === true &&
              poi.title === stringResource.portfolio.label.aboutme ? (
                <div className={'poi__item__link'}>
                  <Link to='Skills'>
                    View skills and projects &nbsp;
                    <span className={'poi__item__link__icon'}>
                      <FontAwesomeIcon className='fa-sm' icon={faLink} />
                    </span>
                  </Link>
                </div>
              ) : (
                <div> </div>
              )}
            </>
            {poi.displayLink === true &&
            poi.title === stringResource.portfolio.label.hobbies ? (
              <div className={'poi__item__link'}>
                <a
                  href={stringResource.portfolio.faeuterowebsite}
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  {stringResource.portfolio.faeuterowebsitedescr} &nbsp;
                  <span className={'poi__item__link__icon'}>
                    <FontAwesomeIcon className='fa-sm' icon={faMusic} />
                  </span>
                </a>{' '}
              </div>
            ) : (
              <> </>
            )}
          </div>
        );
      })}
    </section>
  );
}
