import React from 'react';
import './DetailsCard.scss';
import { Link } from 'react-router-dom';

function DetailsCard({ props }) {
  return (
    <section className='detailscards'>
      {props.map((job) => {
        return (
          <Link
            className='detailscard'
            to={{
              pathname: `/skills/${job.id}`,
            }}
            key={job.id}
          >
            <ul className='detailscard__details'>
              <li className='detailscard__item'>
                <strong className='detailscard__item__title'>
                  {job.title}
                </strong>
              </li>
              <li className='detailscard__item'>{job.where}</li>
              <li className='detailscard__item'>
                {job.from} - {job.to}
              </li>
              <li className='detailscard__item__container'>
                <img
                  src={job.imgsrc}
                  alt=''
                  className='detailscard__picture'
                ></img>
              </li>
            </ul>
          </Link>
        );
      })}
    </section>
  );
}

export default DetailsCard;
