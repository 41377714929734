import React, { useEffect, useState } from 'react';
import stringResource from '../../resources/strings';
import Header from './Header';
import Form from './Form';
import Todo from './Todo';
import db from '../../firebase';
import firebase from 'firebase';
import Emoji from '../../components/Emoji/Emoji';
import { useAuth } from '../../contexts/AuthContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import './Todo.scss';
import MetaTags from 'react-meta-tags';

function TodoPage() {
  const [userInput, setUserInput] = useState('');
  const [todos, setTodos] = useState([]);
  const { currentUser } = useAuth();

  useEffect(() => {
    document.title = stringResource.portfolio.tools.todolist.name;
    db.collection('todos')
      .where('userId', '==', currentUser.uid)
      .orderBy('todo', 'asc')
      .onSnapshot((snapshot) => {
        setTodos(
          snapshot.docs.map((doc) => ({
            id: doc.id,
            todo: doc.data().todo,
            isComplete: doc.data().isComplete,
            userId: doc.data().userId,
          }))
        );
      });
  }, [currentUser.uid]);

  const handleAdd = (e) => {
    e.preventDefault();

    if (userInput === '') return;

    db.collection('todos').add({
      todo: userInput,
      timestamp: firebase.firestore.FieldValue.serverTimestamp(),
      isComplete: false,
      userId: currentUser.uid,
    });

    document.getElementsByClassName('input--large')[0].value = '';
  };

  const handleResetAll = (e) => {
    e.preventDefault();
    db.collection('todos')
      .where('userId', '==', currentUser.uid)
      .get()
      .then(function (snapshot) {
        snapshot.forEach(function (doc) {
          doc.ref.update({
            isComplete: false,
          });
        });
      });
  };

  return (
    <div className='content todo'>
      <MetaTags>
        <title>Todo App</title>
      </MetaTags>
      <Header className='todo__header' name={currentUser.email} />
      <div className='todo__info'>
        <FontAwesomeIcon
          className='fa-sm'
          icon={faInfoCircle}
          style={{ marginRight: '0.5em' }}
        />
        {stringResource.portfolio.tools.todolist.info}{' '}
        <Emoji label='greentick' symbol='✅' />{' '}
      </div>

      <Form
        className='todo__form'
        inputValue={userInput}
        setUserInput={setUserInput}
        handleAdd={handleAdd}
      />

      <div className='todo__resetAll' onClick={handleResetAll}>
        <span className='todo__info'>
          <FontAwesomeIcon
            className='fa-sm'
            icon={faInfoCircle}
            style={{ marginRight: '0.5em' }}
          />
          {stringResource.portfolio.tools.todolist.resetstatus}
        </span>
      </div>

      <div className='todo__list'>
        {todos.map((todo, id) => (
          <Todo props={todo} key={id} />
        ))}
      </div>
    </div>
  );
}

export default TodoPage;
