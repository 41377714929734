import React from 'react';
import './Post.scss';

function Post(props) {
  return (
    <article className='post'>
      <div className='post__image'>
        <div className='post__image-bg'>
          <img alt='Icon Living' src={props.profileimageurl} />
        </div>
      </div>
      <div className='post__caption'>
        <strong>{props.caption}</strong>
      </div>
    </article>
  );
}
export default Post;
