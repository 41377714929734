import React from 'react';
import MetaTags from 'react-meta-tags';
import jobs from '../../../../JobList';
import personalProjects from '../../../../PersonalProjects';
import { UIDConsumer } from 'react-uid';
import DevIcons from '../../../components/DevIcons/DevIcons';
import stringResource from '../../../resources/strings';
import './Details.scss';

function Details(props) {
  var totalJobs = jobs.concat(personalProjects);
  var jobDetails = {};

  for (var i = 0; i < totalJobs.length; i++) {
    if (totalJobs[i].id == props.match.params.id) {
      jobDetails = totalJobs[i];
    }
  }

  return (
    <div className='contentLight'>
      <MetaTags>
        <title>Details</title>
      </MetaTags>

      <div className='details'>
        <h1 className='details__heading'>
          <a href={jobDetails.website} target='_blank' rel='noreferrer'>
            {jobDetails.where}
          </a>
        </h1>
        <div className='details__logo'>
          <a href={jobDetails.website} target='_blank' rel='noreferrer'>
            <img src={jobDetails.imgsrc} alt='jobLogo'></img>
          </a>
        </div>

        <hr />
        <UIDConsumer>
          {(_, uid) =>
            jobDetails.details.map((item) => {
              {
                {
                  return (
                    <div className='details__item' key={uid(item)}>
                      {item}
                    </div>
                  );
                }
              }
            })
          }
        </UIDConsumer>
        <hr />
        <div className='details__techUsed'>
          {jobDetails.techUsed.map((tech, index) => {
            return (
              <div className='details__techUsed__tech' key={index}>
                {tech}
              </div>
            );
          })}
        </div>
        <DevIcons
          props={jobDetails.techUsed}
          className={stringResource.portfolio.skills.detailsTechSkillsClass}
        />
      </div>
    </div>
  );
}

export default Details;
