import React from 'react';
import emailjs from 'emailjs-com';
import './ContactMe.scss';
import Button from '../Common/Button/Button';
import Input from '../Common/Input/Input';
import Textarea from '../Common/Textarea/Textarea';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

toast.configure();
export default function ContactMe() {
  function sendEmail(e) {
    e.preventDefault();

    emailjs
      .sendForm(
        'service_5nazo5g',
        'template_5wa9sii',
        e.target,
        'user_QBy2RVcCb24BxwPldWq6l'
      )
      .then(
        (result) => {
          toast(result.text + ' Human! Message sent.', {
            position: toast.POSITION.BOTTOM_CENTER,
          });
        },
        (error) => {
          console.log(error.text);
        }
      );
  }

  return (
    <div className='content contactMe'>
      <h1 className='contactMe__heading'>How can I help?</h1>
      <card>
        <body>
          <h2 className='contactMe__title'>Contact Me</h2>
          <div className='contactMe__avatarContainer'>
            <div className='contactMe__avatar'></div>
          </div>
          <p className='contactMe__message'>
            I hope you had a good experience.
          </p>
          <p className='contactMe__message'>
            If you ran into trouble, require any further assistance or have
            questions please reach out to me using the form below.
          </p>
        </body>
        <form onSubmit={sendEmail} className='contactMe__form'>
          <group id='Name'>
            <Input
              size='large'
              type='hidden'
              name='from_name'
              value='Ankit Mahendru Contact Me'
            />
            <Input
              size='large'
              placeholder='Name'
              type='text'
              name='to_name'
              required
            />
          </group>

          <group id='Email'>
            <Input
              size='large'
              placeholder='Email Address'
              type='email'
              name='user_email'
              required
            />
          </group>
          <group id='Message'>
            <Textarea
              size='large'
              placeholder='Write a message'
              as='textarea'
              name='message'
              rows='8'
              cols='100'
            />
          </group>
          <group id='Send'>
            <Button
              label='Send'
              size='medium'
              style={{ marginBottom: '1em' }}
              type='submit'
              primary={true}
            ></Button>
          </group>
        </form>
      </card>
    </div>
  );
}
