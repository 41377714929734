import React from 'react';
import listOfProducts from '../../../ProductList';
import Button from '../../components/Common/Button/Button';

import './Store.scss';

export default function Store() {
  return (
    <div className='content store'>
      <section className={'store__products'}>
        {listOfProducts.map((poip, key) => {
          return (
            <div className={'store__product'} key={key}>
              <div className={'store__product__image'}>
                <img src={poip.image} alt='Product Picture'></img>
              </div>
              <div className={'store__product__title'}>{poip.title}</div>
              <div className={'store__product__description'}>
                {poip.description}
              </div>
              <div className='store__product__posting'>
                {poip.ispostingincluded ? (
                  <small>Posting included</small>
                ) : (
                  <small>Digital download</small>
                )}
              </div>
              <div className={'store__product__price'}>£{poip.price}</div>
              <div className={'store__product__add'}>
                <Button label='Add' size='medium' primary={true}>
                  <span></span>
                </Button>
              </div>
            </div>
          );
        })}
      </section>
    </div>
  );
}
