import React from 'react';
import Input from '../Common/Input/Input';
import Button from '../Common/Button/Button';

import './RecipeIngredientEdit.scss';

export default function RecipeIngredientEdit(props) {
  const { ingredient, handleIngredientChange, handleIngredientDelete } = props;

  function handleChange(changes) {
    handleIngredientChange(ingredient.id, { ...ingredient, ...changes });
  }
  return (
    <>
      <Input
        size='large'
        type='text'
        value={ingredient.name}
        onInput={(e) => handleChange({ name: e.target.value })}
      />
      <Input
        size='large'
        type='text'
        value={ingredient.amount}
        onInput={(e) => handleChange({ amount: e.target.value })}
      />

      <Button
        onClick={() => handleIngredientDelete(ingredient.id)}
        size='small'
        label='X'
      ></Button>
    </>
  );
}
