import imagesResource from './client/resources/images';
import stringResource from './client/resources/strings';
export default [
  {
    title: stringResource.portfolio.education.cert1.title,
    where: stringResource.portfolio.education.cert1.where,
    from: stringResource.portfolio.education.cert1.from,
    to: stringResource.portfolio.education.cert1.to,
    id: stringResource.portfolio.education.cert1.id,
    imgsrc: imagesResource.education.microsoftcertification,
  },
  {
    title: stringResource.portfolio.education.cert2.title,
    where: stringResource.portfolio.education.cert2.where,
    from: stringResource.portfolio.education.cert2.from,
    to: stringResource.portfolio.education.cert2.to,
    id: stringResource.portfolio.education.cert2.id,
    imgsrc: imagesResource.education.microsoftcertification,
  },
  {
    title: stringResource.portfolio.education.cert3.title,
    where: stringResource.portfolio.education.cert3.where,
    from: stringResource.portfolio.education.cert3.from,
    to: stringResource.portfolio.education.cert3.to,
    id: stringResource.portfolio.education.cert3.id,
    imgsrc: imagesResource.education.microsoftcertification,
  },
];
