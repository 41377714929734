import React, { useRef, useState } from 'react';
import { useAuth } from '../../../contexts/AuthContext';
import { Link, useHistory } from 'react-router-dom';
import MetaTags from 'react-meta-tags';
import Button from '../../Common/Button/Button';
import Input from '../../Common/Input/Input';
import './SignUp.scss';

function SignUp() {
  const emailRef = useRef();
  const passwordRef = useRef();
  const passwordConfirmRef = useRef();
  const { signup } = useAuth();
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  async function handleSubmit(e) {
    e.preventDefault();

    if (passwordRef.current.value !== passwordConfirmRef.current.value) {
      return setError('Passwords do not match');
    }

    try {
      setError('');
      setLoading(true);
      await signup(emailRef.current.value, passwordRef.current.value);
      history.push('/email-verification');
    } catch {
      setError('Failed to sign in');
    }

    setLoading(false);
  }
  return (
    <div className='content signup'>
      <MetaTags>
        <title>Sign Up</title>
      </MetaTags>
      <card>
        <body>
          <div className='signup__logoContainer'>
            <div className='signup__logo'></div>
          </div>
          <h2 className='signup__title'>Sign Up</h2>
          {error && <alert variant='danger'> {error}</alert>}
        </body>

        <form onSubmit={handleSubmit} className='signup__form'>
          <group id='email'>
            <Input
              size='large'
              type='email'
              ref={emailRef}
              required
              placeholder='Email Address'
            />
          </group>
          <group id='password'>
            <Input
              size='large'
              type='password'
              ref={passwordRef}
              required
              placeholder='Password'
            />
          </group>
          <group id='password-confirm'>
            <Input
              size='large'
              type='password'
              ref={passwordConfirmRef}
              required
              placeholder='Password Confirmation'
            />
          </group>
          <Button
            disabled={loading}
            label='Sign Up'
            size='medium'
            type='submit'
            primary={true}
          ></Button>
        </form>
        <div className='signup__question'>Already have an account?</div>

        <div className='signup__login'>
          <Link to='/login'> Log In </Link>
        </div>
      </card>
    </div>
  );
}

export default SignUp;
