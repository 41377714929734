import logo from '../img/logo.png';
import avatar from '../img/avatar.png';
import masterspicture from '../img/masters.jpg';
import bachelorspicture from '../img/bachelors.jpg';
import microsoftcertification from '../img/microsoft_certification.png';

import nable from '../img/work/nable.png';
import cct from '../img/work/cct.png';
import ceridianpicture from '../img/work/ceridian.png';
import sqapicture from '../img/work/sqa.png';
import spaarkspicture from '../img/work/spaarks.png';
import tcbPicture from '../img/work/tcb.png';
import scmPicture from '../img/work/scm.jpg';
import storypoint from '../img/work/storypoint.png';

import d1 from '../img/pictures/d1.jpg';
import d2 from '../img/pictures/d2.jpg';
import d3 from '../img/pictures/d3.jpeg';
import d4 from '../img/pictures/d4.jpg';
import d5 from '../img/pictures/d5.jpg';
import d6 from '../img/pictures/d6.jpg';
import d7 from '../img/pictures/d7.jpg';
import d8 from '../img/pictures/d8.jpg';
import d9 from '../img/pictures/d9.jpg';
import d10 from '../img/pictures/d10.JPG';
import d11 from '../img/pictures/d11.JPG';
import d12 from '../img/pictures/d12.jpg';
import d13 from '../img/pictures/d13.JPG';
import d14 from '../img/pictures/d14.JPG';
import d15 from '../img/pictures/d15.JPG';
import d16 from '../img/pictures/d16.jpg';
import d17 from '../img/pictures/d17.jpg';
import d18 from '../img/pictures/d18.JPG';
import d19 from '../img/pictures/d19.jpg';
import d20 from '../img/pictures/d20.jpg';
import d21 from '../img/pictures/d21.jpg';
import d22 from '../img/pictures/d22.jpg';
import d23 from '../img/pictures/d23.jpg';
import d24 from '../img/pictures/d24.JPG';
import d25 from '../img/pictures/d25.JPG';
import d26 from '../img/pictures/d26.jpg';
import d27 from '../img/pictures/d27.jpg';
import d28 from '../img/pictures/d28.jpg';
import d29 from '../img/pictures/d29.jpg';
import d30 from '../img/pictures/d30.jpg';
import d31 from '../img/pictures/d31.jpg';
import d32 from '../img/pictures/d32.jpg';

import artistlogo from '../img/artistlogo.png';
import bandlogo from '../img/band-logo.png';
import hob from '../img/Products/hob.png';
import gwlc from '../img/Products/gwlc.png';
import lt from '../img/Products/lt.png';
import hw from '../img/Products/hw.png';
import introspect from '../img/Products/introspect.png';

import html from '../img/icons/html.svg';
import css from '../img/icons/css.svg';
import csharp from '../img/icons/csharp.png';
import firebase from '../img/icons/firebase.svg';
import git from '../img/icons/git.svg';
import javascript from '../img/icons/javascript.svg';
import windows from '../img/icons/windows.svg';
import react from '../img/icons/react.svg';
import sass from '../img/icons/sass.svg';
import vscode from '../img/icons/vscode.svg';
import sql from '../img/icons/sql.png';
import mvc from '../img/icons/mvc.png';
import ts from '../img/icons/ts.png';
import angular from '../img/icons/angular.png';
import dojo from '../img/icons/dojo.png';
import jquery from '../img/icons/jquery.png';
import core from '../img/icons/core.png';
import java from '../img/icons/java.png';
import subversion from '../img/icons/subversion.png';
import ionic from '../img/icons/ionic.png';
import tfs from '../img/icons/tfs.png';
import stripe from '../img/icons/stripe.png';
import netlify from '../img/icons/netlify.png';
import spotify from '../img/icons/spotify.png';
import appleMusic from '../img/icons/apple.png';
import bandcamp from '../img/icons/bandcamp.png';
import soundcloud from '../img/icons/soundcloud.png';
import visualstudio from '../img/icons/visualstudio.png';
import youtube from '../img/icons/youtube.png';
import spotifylogo from '../img/icons/spotify-logo.png';
import instalogo from '../img/icons/insta.png';
import faeutero from '../img/icons/faeutero.png';
import teapot from '../img/teapot.png';

const images = {
  home: {
    avatar: avatar,
    logo: logo,
  },
  education: {
    masterspicture: masterspicture,
    bachelorspicture: bachelorspicture,
    microsoftcertification: microsoftcertification,
  },
  skills: {
    nablepicture: nable,
    cctpicture: cct,
    ceridianpicture: ceridianpicture,
    sqapicture: sqapicture,
    spaarkspicture: spaarkspicture,
    perosnalProjects: {
      tcbPicture: tcbPicture,
      amwPicture: logo,
      scmPicture: scmPicture,
      storypoint: storypoint,
      faeutero: faeutero,
    },
  },
  media: {
    d1: d1,
    d2: d2,
    d3: d3,
    d4: d4,
    d5: d5,
    d6: d6,
    d7: d7,
    d8: d8,
    d9: d9,
    d10: d10,
    d11: d11,
    d12: d12,
    d13: d13,
    d14: d14,
    d15: d15,
    d16: d16,
    d17: d17,
    d18: d18,
    d19: d19,
    d20: d20,
    d21: d21,
    d22: d22,
    d23: d23,
    d24: d24,
    d25: d25,
    d26: d26,
    d27: d27,
    d28: d28,
    d29: d29,
    d30: d30,
    d31: d31,
    d32: d32,
  },
  music: {
    hob: hob,
    artistlogo: artistlogo,
    bandlogo: bandlogo,
    spotifylogo: spotifylogo,
    spotify: spotify,
    appleMusic: appleMusic,
    bandcamp: bandcamp,
    soundcloud: soundcloud,
    youtube: youtube,
  },
  product: {
    hob: hob,
    gwlc: gwlc,
    lt: lt,
    hw: hw,
    introspect: introspect,
  },
  icons: {
    html: html,
    css: css,
    csharp: csharp,
    firebase: firebase,
    git: git,
    javascript: javascript,
    windows: windows,
    react: react,
    sass: sass,
    vscode: vscode,
    sql: sql,
    mvc: mvc,
    ts: ts,
    angular: angular,
    dojo: dojo,
    jquery: jquery,
    core: core,
    java: java,
    subversion: subversion,
    ionic: ionic,
    tfs: tfs,
    stripe: stripe,
    netlify: netlify,
    visualstudio: visualstudio,
    instalogo: instalogo,
  },
  notfound: {
    teapot: teapot,
  },
};

export default images;
