import React from 'react';
import { withRouter } from 'react-router';
import NavItem from '../NavItem/NavItem';
import NavItems from '../../../NavbarList';
import { useAuth } from '../../contexts/AuthContext';
import { Link, useHistory, useLocation } from 'react-router-dom';
import './Navbar.scss';

function Navbar({ ...props }) {
  const location = useLocation();
  const { currentUser, logout } = useAuth();
  const history = useHistory();
  async function handleLogout() {
    try {
      await logout();
      history.push('/');
    } catch {
      console.log('Failed to log out');
    }
  }
  return (
    <div {...props}>
      <ul>
        <NavItem NavItems={NavItems}></NavItem>
        {currentUser ? (
          <>
            <div className='menuItem'>
              <li>
                <Link to='/update-profile'>Profile</Link>
              </li>
            </div>

            {currentUser && currentUser.emailVerified ? (
              location.pathname === '/dashboard' ? (
                ''
              ) : (
                <div className='menuItem'>
                  <li>
                    <Link to='/dashboard'>Dashboard</Link>
                  </li>
                </div>
              )
            ) : (
              ''
            )}
            <div className='menuItem'>
              <li>
                <Link to='' onClick={handleLogout}>
                  Log Out
                </Link>
              </li>
            </div>
          </>
        ) : (
          ''
        )}
      </ul>
    </div>
  );
}

export default withRouter(Navbar);
