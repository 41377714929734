import React from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../../../contexts/AuthContext';
import './EmailVerification.scss';
import { toast } from 'react-toastify';
import Emoji from '../../../components/Emoji/Emoji';
import 'react-toastify/dist/ReactToastify.css';
import { useHistory } from 'react-router-dom';
import Button from '../../Common/Button/Button';
toast.configure();

export default function EmailVerification() {
  const { sendVerification, currentUser, logout } = useAuth();
  const history = useHistory();

  async function handleEmailVerification() {
    await sendVerification();
    toast('Email verification sent. Please check your email for details');
  }
  async function handleLogout() {
    await logout();
    history.push('/login');
  }

  return (
    <div className='content email-verification'>
      <div className='email-verification__logo'></div>
      <div className='email-verification__title'>
        {currentUser ? (
          <div className='email-verification__message'>
            We have sent a verification email to{' '}
            <a href={`mailto: ${currentUser.email}`}>{currentUser.email}</a>
          </div>
        ) : (
          'We have sent a verification email'
        )}
      </div>
      <p>
        If you&apos;ve verified your email, click{' '}
        <Link
          className='email-verification__dashboard'
          onClick={handleLogout}
          to='/login'
        >
          here
        </Link>
        {' to log in.'}
      </p>
      <p>
        Didn&apos;t recieve a verification email? We got you{' '}
        <Emoji label='Smile' symbol='😊' /> Use the button below to send out a
        new verification email.
      </p>
      <Button
        label='Send Verification'
        size='medium'
        primary={true}
        onClick={handleEmailVerification}
      ></Button>
    </div>
  );
}
