const strings = {
  portfolio: {
    username: 'Ankit Mahendru',
    shortname: 'Ankit',
    website: 'https://ankitmahendru.com/',
    pagetitle: 'Contact',
    email: 'Email',
    emaillink: 'mailto:ankit.mahendru@gmail.com',
    github: 'GitHub',
    githublink: 'https://github.com/mahendruankit',
    faeuterowebsite: 'https://faeutero.com',
    faeuterowebsitedescr: 'View my music website here',
    githubrepolink: 'https://github.com/mahendruankit/am-portoflio',
    youtube: 'Youtube',
    youtubelink: 'https://www.youtube.com/channel/UCg2zJzFO0yrYL7YZ1aHXxkg',
    bandcamp: 'Bandcamp',
    bandcamplink: 'https://fae-utero.bandcamp.com/',
    soundcloud: 'https://soundcloud.com/user-67884008',
    hillofbeansyoutubelink:
      'https://www.youtube.com/watch?v=RvHuVW_hA-0&list=OLAK5uy_kOBqEmWfPvTI5Rw_B_7lkPNK8cLrGFK_k&ab_channel=FaeUtero-Topic',
    bandcamplinkgwlcsingle:
      'https://fae-utero.bandcamp.com/track/girl-who-likes-chocolate',
    gwlcyoutubelink: 'https://youtu.be/dSQ3okqc91Y',
    faeuterolivelink: 'https://www.youtube.com/watch?v=rZcYHbKHoSA',
    reactjswebsite: 'https://reactjs.org/',
    netlifywebsite: 'https://www.netlify.com/',
    firebasewebsite: 'https://firebase.google.com/',
    spotify: 'https://open.spotify.com/artist/2jgvsnDdf6qCve1pikbDqh',
    spotifyembed:
      'https://open.spotify.com/embed/artist/2jgvsnDdf6qCve1pikbDqh',
    apple: 'https://music.apple.com/gb/artist/fae-utero/1510312644',
    spotifyfollowlink:
      'https://open.spotify.com/follow/1/?uri=spotify:artist:2jgvsnDdf6qCve1pikbDqh?si=f30B4aqOT7et1tSffl_IaQu&size=detail&theme=dark',
    youtubelinkltsingle: 'https://youtu.be/dPy0OyQblco',
    youtubelinkhwsingle: 'https://youtu.be/IOoh14F3e0k',
    introspectsingle: 'https://youtu.be/FH43bDMRG9s',
    gdpr: 'Just a moment! This website uses cookies to enhance the user experience. If you continue to browse this site, you are agreeing to our use of cookies. You can disable cookies at any time within your browser settings.',
    gdprbutton: 'Sounds good',
    typeeffect: ['Hello World!', 'I am Ankit Mahendru', 'K bye.'],
    notfound418: 'https://httpstatuses.org/418',
    label: {
      development: 'Website',
      advance: 'Advanced',
      intermediate: 'Intermediate',
      softskills: 'Soft Skills',
      aboutme: 'About Me',
      recipes: 'Recipes',
      musicvideos: 'Music Videos',
      musicvideosdecr: 'Originals & Covers',
      contactme: 'Contact',
      developer: 'Developer',
      musician: 'Musician',
      caninophile: 'Caninophile',
      faeutero: 'FAE UTERO',
      signUp: 'Sign Up',
      logIn: 'Log In',
      hobbies: 'Hobbies',
    },
    home: {
      greeting: 'Hello World!',
      pagetitle: 'Ankit Mahendru',
      descr: 'Home Page',
      devdescr:
        'The website demonstrates how one can use ReactJS, Netlify and Firebase to provide a quick integration of a realtime backend in a React app.\n\n Netlify works by connecting to your GitHub repository. It fetches your source code which is then followed by a build process to pre-render pages in static HTML. React encapsulates components that manage their own state, which then can be composed to make complex UIs. This is complemented by Firebase, a toolset by Google which includes authentication, databases, file storage and much more.',
      musicsdescr:
        'Straight up white boy rock made by a brown guy. \n\n An independent artist with lyrics that tap into substantial thought and reflection with a focus on real-life experiences. The band name translates to “from the womb”.',
      linkmedescr: 'cringe 🤌',
      aboutmedescr:
        'A passionate software developer with several years of experience in web development complemented by a well-rounded knowledge of web frameworks. A keen eye for automation, performance optimization and data visualization. In addition, a proven track record of delivering successful projects and programs across a diverse range of technologies. \n\nQuick learner with the ability to support business partners and technology teams to provide successful deliveries. Excellent communication skills and global experience working with teams and stakeholders based in EMEA, Americas & Asia-Pacific.',
      buymusic: 'Buy albums and singles',
      buymusicbandcamp: 'Buy albums and singles on Bandcamp',
      bandcamp: 'Buy albums and singles',
      viewvideos: 'Watch videos',
      hobbiesClass: 'home__section__hobbies',
      linkmedecr: 'View all links',
      hobbiesdescr:
        'I enjoy hill walks with my dog-children. I have three border collies and yes, you guessed it, they are wired to the moon! I don’t think I have a choice in the matter when it comes to long walks and 6:00 AM starts but I secretly love it shhhhhhhh..\n\nI play the guitar, make original compositions and edit videos. It has always been a cherished hobby and is an ideal conﬂuence of my interest in the arts. If you fancy listening to some of my music, click on the link below!',
    },
    education: {
      pagetitle: 'Education',
      masters: {
        title: 'Masters of Science Information Technology',
        where: 'University of Glasgow, Scotland',
        from: '2010',
        to: '2011',
        id: 'masters',
      },
      bachelors: {
        title: 'Bachelor of Science Information Technology',
        where: 'University of Mumbai, India',
        from: '2006',
        to: '2009',
        id: 'bachelors',
      },
      cert1: {
        title: 'Administering Visual Studio TFS',
        where: 'Exam ID: 070496',
        from: '2013',
        to: '',
        id: 'tfs1',
      },
      cert2: {
        title: 'Windows Based Client Development',
        where: 'Exam ID: 526',
        from: '2010',
        to: '',
        id: 'tfs2',
      },
      cert3: {
        title: 'Application Development Foundation',
        where: 'Exam ID: 536',
        from: '2009',
        to: '',
        id: 'tfs3',
      },
    },
    skills: {
      pagetitle: 'Skills',
      detailsTechSkillsClass: 'details__tech',
      all: 'C#.Net, ASP.NET MVC, Javascript, Typescript, ReactJS, C#.Net Core, JQuery, SQL, Angular, Java, GIT, Dojo, CSS, Sass, Visual Studio Code, Subversion, Ionic, TFS, FireBase, Stripe, Netlify, Visual Studio',
      job5: {
        id: 5,
        title: 'Senior Software Developer',
        where: 'N-able',
        from: 'NOV 2021',
        to: 'Present',
        techUsed: [
          'C#.Net',
          'Typescript',
          'Angular',
          'GIT',
          'Visual Studio Code',
        ],
        website: 'https://www.n-able.com/',
        details: [
          'Develop and support DMA (Device management for apple)',
          'Onboard customers for MSPs and assist them to register, view and manage push certificates',
          'View and deploy assets (apps) & configuration profiles on devices',
          'Build in-house components to aid developers',
          'Review micro-services that serve DMA',
          'Integrate storybook and build a comprehensive component library',
          'Deployments via Azure and managed Kubernetes services',
        ],
      },
      job4: {
        id: 4,
        title: 'Senior Software Developer',
        where: 'CCT',
        from: 'APR 2019',
        to: 'NOV 2021',
        techUsed: [
          'ReactJS',
          'C#.Net',
          'ASP.NET MVC',
          'SQL',
          'Typescript',
          'Sass',
          'Angular',
          'GIT',
          'Visual Studio',
          'Visual Studio Code',
        ],
        website: 'https://cctechnology.com/',
        details: [
          'Develop and support Grant Tracker (Grant Management System)',
          'Extend Funding Solution API (ASP.NET Core API using MediatR and CQRS)',
          'ntegrate UI/UX designs with stories in Storybook',
          'Development to extend Public Portal (AngularJS)',
          'Design new authentication components using TypeScript for React',
          'Modernizing an MVC application with multiple React features',
          'Schema related changes for an inhouse Entity Generator. A windows application that makes use of Subsonic ORM',
          'Implement BEM methodology',
        ],
      },
      job3: {
        id: 3,
        title: 'Software Developer',
        where: 'Ceridian Europe Ltd',
        from: 'NOV 2017',
        to: 'APR 2019',
        techUsed: [
          'C#.Net',
          'ASP.NET MVC',
          'Dojo',
          'SQL',
          'Typescript',
          'Javascript',
          'JQuery',
          'Visual Studio',
          'GIT',
          'TFS',
        ],
        website: 'https://www.ceridian.com/uk',
        details: [
          'Develop and support HR system',
          'Implement features such as Global Direct Deposit and Payzone Management',
          'Work on Letter/Contract management for employees and candidates',
          'Develop and maintain candidate portal',
          'Re-design account recovery assistant',
          'Configure workflows for approval processes',
          'Reports Management (Build and alter reports using database entities)',
          'Enhance imports and exports (HR & Org)',
          'Generate tax forms using internal tools that made use of imagemagick and ghostscript',
          'Assist colleagues during TFS/GIT migration',
        ],
      },
      job2: {
        id: 2,
        title: 'Software Developer',
        where: 'SQA',
        from: 'JUN 2016',
        to: 'JUN 2017',
        techUsed: [
          'C#.Net Core',
          'Java',
          'ASP.NET MVC',
          'SQL',
          'Javascript',
          'JQuery',
          'Visual Studio',
          'Subversion',
        ],
        website: 'https://www.sqa.org.uk/',
        details: [
          'Migrating IBM WebSphere applications (Java) and existing Ingres databases to Microsoft .Net stack using EF6 Code first using ASP.NET MVC 5 & Microsoft SQL 2012',
          'Building web services using SoapUI and Automapper',
          'Configure web apps and setup continuous deployments with TeamCity to IIS server',
          'Oversee development work done by 3rd parties along with code reviews and user testing',
          'Assisting in scoping efforts for new developments & transitions of existing technologies to new efficient models',
        ],
      },
      job1: {
        id: 1,
        title: 'Web Application Developer',
        where: 'Spaarks',
        from: 'JUL 2011',
        to: 'JUN 2016',
        techUsed: [
          'C#.Net',
          'ASP.NET MVC Code First',
          'Ionic',
          'Angular',
          'SQL',
          'Javascript',
          'JQuery',
          'Visual Studio',
          'GIT',
          'TFS',
        ],
        website: 'https://spaarks.com/',
        details: [
          'Develop, support and optimize applications for Deutsche Bank network, Vivacity RTO Coaching & ACT Health',
          'Work closely with end users to provide front line support and improve performance dramatically within tight timescales',
          'Develop and maintain pass office and UBR structural changes across various access control systems for Deutsche Bank CSBC Group',
          'Incorporate integration to Deutsche Bank Visitor Management systems and Smartcard physical identification cards',
          'Develop code and supported various banking applications that deal with onboarding of contractors and third party vendors in Germany and Singapore',
          'Manage costs and policy compliance data from various sources across DB and provide standardized end user reporting by UBR, Cost Centre and region',
          'Develop and support a fully auditable online process for external requests and resources',
          'Gathered business requirements, designed solutions with support from technical specialists and managed workload across the team',
          'Implement new workflows for disaster recovery reporting systems within the bank',
          'Implement Restful web services to provide consistent HR data feeds within Deutsche Bank GTO group',
          'Pull together a number of datasets from numerous sources into a single, central reporting solution',
          'Implement rich interactive dashboards that demonstrate time spent against cost management policies and obligations for ACT Health in Australia',
          'Develop cross platform mobile apps that help users manage and lend out medical training equipment to their members',
          'Service desk management – Service Now, Zendesk, Jira',
        ],
      },
      advancedSkills: [
        'Angular using TypeScript \n\n',
        'React using JavaScript \n\n',
        'ASP.NET MVC & Web Forms using C# \n\n',
        'HTML, CSS and SCSS \n\n',
        'SQL \n\n',
        'GIT \n\n',
      ],
      intermediateSkills: [
        'Mediator Patterns With ASP.NET Core Web API\n\n',
        'Unit tests with Jest, Jasmine along with ng-mocks library \n\n',
        'E2E tests with Playwright & Protractor. Design & accessibility tests using Storybook\n\n',
        'Netlify and Firebase\n\n',
        'Continuous integration with Azure & Bitbucket\n\n',
      ],
      softSkills: [
        'Stakeholder management using agile and waterfall methodologies\n\n',
        'Ability to evaluate and assess technical requirements\n\n',
        'Introduce engineering practices, identify gaps, review and implement business improvements\n\n',
        'Team player who is committed to the scrum values and practices',
      ],
      perosnalProjects: {
        job1: {
          id: 'tcb',
          title: 'Web Developer',
          where: 'The Common Breath',
          from: 'APR 2020',
          to: 'DEC 2020',
          techUsed: ['HTML', 'CSS', 'Visual Studio Code'],
          website: 'https://thecommonbreath.com/',
          details: [
            'Develop and support shop section of the common breath website',
            'Integrate website with paypal forms in order to make payments.',
            'Introduce an open-source CSS framework (Bootstrap) and implement BEM CSS methodology',
            'Make new responsive menu component',
            'Reduction in legacy code',
          ],
        },
        job2: {
          id: 'faeutero',
          title: 'Web Developer',
          where: 'FAE UTERO',
          from: 'JUL 2022',
          to: 'Present',
          techUsed: [
            'ReactJS',
            'Stripe',
            'Netlify',
            'Sass',
            'Javascript',
            'Visual Studio Code',
          ],
          website: 'https://www.faeutero.com/',
          details: [
            'Develop and support website',
            'Use ReactJS in conjunction with Netlify.',
            'Integrate website with Stripe API in order to make payments for songs and EPs.',
            'Implement BEM CSS methodology.',
          ],
        },
      },
      tools: {
        pagetitle: 'Tools',
        todolist: {
          name: 'Todo List',
          description: 'Prioritise and plan tasks',
          info: 'Click the hollow red circle to mark it as complete ',
          resetstatus: 'Reset status',
          resetstatusinfo: '(This will mark all items as incomplete)',
        },
        recipe: {
          description: 'Create recipes instantly!',
        },
      },
    },
    pictures: {
      pagetitle: 'Media',
      descr: 'Pictures & Videos',
      petusername: 'Ankit',
      d1: {
        caption: 'Snow pups',
      },
      d2: {
        caption: 'Should have stayed in :|',
      },
      d3: {
        caption: 'Country park walkies',
      },
      d4: {
        caption: 'Puppy Ziggy <3',
      },
      d5: {
        caption: 'Top of Beinn Bhreac',
      },
      d6: {
        caption: 'Puppy Cookie',
      },
      d7: {
        caption: 'Balloch',
      },
      d8: {
        caption: 'Reservoir dogs',
      },
      d9: {
        caption: "Chillin'",
      },
      d10: {
        caption: 'Fort William',
      },
      d11: {
        caption: 'Isle of Skye',
      },
      d12: {
        caption: 'Conic Hill, Balmaha',
      },
      d13: {
        caption: 'Italy',
      },
      d14: {
        caption: 'Battle of Stirling bridge',
      },
      d15: {
        caption: 'Puppy Cookie <3',
      },
      d16: {
        caption: 'Views from Ben Arthur',
      },
      d17: {
        caption: 'Pensive Ziggy | Orange sky',
      },
      d18: {
        caption: 'The Gateway of India, Mumbai',
      },
      d19: {
        caption: 'Ben Lomond in the backdrop',
      },
      d20: {
        caption: 'Parallel processing | Ziggy & Jura',
      },
      d21: {
        caption: 'Isle of Arran, Scotland',
      },
      d22: {
        caption: 'Painting by Alison Hutton',
      },
      d23: {
        caption: 'Thugs...',
      },
      d24: {
        caption: 'New York',
      },
      d25: {
        caption: 'Disney World, Florida',
      },
      d26: {
        caption: 'Cookie & Lil Ziggy at Mugdock',
      },
      d27: {
        caption: 'Queen Cookie',
      },
      d28: {
        caption: 'Munich, Germany',
      },
      d29: {
        caption: 'The Storr, Isle of Skye',
      },
      d30: {
        caption: 'Quiraing, Isle of Skye',
      },
      d31: {
        caption: 'Coral Beach, Isle of Skye',
      },
      d32: {
        caption: 'Is the breakfast ready? Isle of Skye',
      },
    },
    videos: {
      pagetitle: 'Videos',
      descr: 'Videos',
    },
    dashboard: {
      pagetitle: 'Dashboard',
      greeting: 'Welcome, ',
    },
    tools: {
      pagetitle: 'Tools',
      todolist: {
        name: 'Todo List',
        description: 'Prioritise and plan tasks',
        info: 'Click the red circle to mark it as complete ',
        resetstatus: 'Reset status will mark all items as incomplete',
      },
    },
  },
};
export default strings;
