import React, { useState } from 'react';
import { HashRouter, Route } from 'react-router-dom';

import Navbar from './components/Navbar/Navbar';
import Footer from './components/Footer/Footer';
import Breadcrumb from './components/Breadcrumb/Breadcrumb';
import ScrollToTop from './components/ScrollToTop/ScrollToTop';
import PrivateRoute from './components/Authentication/PrivateRoute/PrivateRoute';
import CookieConsent from 'react-cookie-consent';
import Authprovider from './contexts/AuthContext';
import Header from './components/Common/Header/Header';
import stringResource from './resources/strings';
import ReactGA from 'react-ga';

import routes from '../PageRouteList';

import './App.scss';

function App() {
  const TRACKING_ID = 'G-9YJK6XDJCV'; // YOUR_OWN_TRACKING_ID
  ReactGA.initialize(TRACKING_ID);

  const [header, setHeader] = useState(false);
  const [navbar, setNavbar] = useState(false);

  const changeBackground = () => {
    if (window.scrollY >= 10) {
      setHeader(true);
      setNavbar(true);
    } else {
      setHeader(false);
      setNavbar(false);
    }
  };

  window.addEventListener('scroll', changeBackground);
  return (
    <div className='app'>
      <div className='page'>
        <HashRouter basename={process.env.PUBLIC_URL}>
          <Authprovider key={0}>
            <Header className={header ? 'header active' : 'header'}>
              <Navbar className={navbar ? 'navbar active' : 'navbar'} />
            </Header>
          </Authprovider>
          <ScrollToTop />
          {routes.map(({ path, Component, usesAuth, isPrivate }, key) =>
            usesAuth && isPrivate ? (
              <Authprovider key={key}>
                <PrivateRoute
                  exact
                  path={path}
                  key={key}
                  component={Component}
                />
              </Authprovider>
            ) : usesAuth ? (
              <Authprovider key={key}>
                <Route
                  exact
                  path={path}
                  key={key}
                  render={(props) => {
                    const crumbs = routes
                      .filter(({ path }) => props.match.path.includes(path))
                      .map(({ path, ...rest }) => ({
                        path: Object.keys(props.match.params).length
                          ? Object.keys(props.match.params).reduce(
                              (path, param) =>
                                path.replace(
                                  `:${param}`,
                                  props.match.params[param]
                                ),
                              path
                            )
                          : path,
                        ...rest,
                      }));
                    return (
                      <>
                        <Breadcrumb crumbs={crumbs} />
                        <Component {...props} />
                      </>
                    );
                  }}
                />
              </Authprovider>
            ) : (
              <Route
                exact
                path={path}
                key={key}
                render={(props) => {
                  const crumbs = routes
                    .filter(({ path }) => props.match.path.includes(path))
                    .map(({ path, ...rest }) => ({
                      path: Object.keys(props.match.params).length
                        ? Object.keys(props.match.params).reduce(
                            (path, param) =>
                              path.replace(
                                `:${param}`,
                                props.match.params[param]
                              ),
                            path
                          )
                        : path,
                      ...rest,
                    }));
                  /** print crumbs on console
                              console.log(`Generated crumbs for ${props.match.path}`);
                              crumbs.map(({ name, path }) => console.log({ name, path }));
                              **/
                  return (
                    <>
                      <Breadcrumb crumbs={crumbs} />
                      <Component {...props} />
                    </>
                  );
                }}
              />
            )
          )}
          <Footer />
        </HashRouter>
        <CookieConsent
          location='bottom'
          buttonText={stringResource.portfolio.gdprbutton}
          cookieName='gdprCookie'
          style={{
            background: '#ffe5b4',
            fontSize: '1em',
            color: 'rgba(0, 0, 0, 0.87)',
          }}
          buttonStyle={{
            margin: '1em',
            border: 0,
            fontWeight: 'bold',
            fontSize: '1em',
            background: '#3071e8ff',
            padding: '0 2em',
            height: '3em',
            lineHeight: '3em',
            borderRadius: '2.5em',
            color: '#ffffff',
            fontfamily: 'Roboto',
          }}
          expires={150}
        >
          {stringResource.portfolio.gdpr}
        </CookieConsent>
      </div>
    </div>
  );
}

export default App;
