import React from 'react';
import stringResource from '../../resources/strings';
import DetailsCard from '../../components/DetailsCard/DetailsCard';
import DisplayCard from '../../components/DisplayCard/DisplayCard';
import PointOfIntrest from '../../components/PointOfIntrest/PointOfIntrest';
import MetaTags from 'react-meta-tags';
import jobs from '../../../JobList';
import personalProjects from '../../../PersonalProjects';
import academics from '../../../AcademicsList';
import certifications from '../../../CertificationsList';
import DevIcons from '../../components/DevIcons/DevIcons';
import Button from '../../components/Common/Button/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faLayerGroup,
  faTerminal,
  faComment,
  faLaptopCode,
  faLaptop,
  faCode,
  faBook,
} from '@fortawesome/free-solid-svg-icons';

import './Skills.scss';

function Skills() {
  function downloadCV() {
    var FileSaver = require('file-saver');
    FileSaver.saveAs(
      process.env.PUBLIC_URL + '/Files/Ankit_Mahendru_CV.pdf',
      'Ankit_Mahendru_CV.pdf'
    );
  }
  const listOfPOI = [
    {
      title: stringResource.portfolio.label.advance,
      content: stringResource.portfolio.skills.advancedSkills,
      icon: (
        <FontAwesomeIcon
          className='fa-sm skills__title__icon'
          icon={faTerminal}
          style={{ marginRight: '0.5em' }}
        />
      ),
    },
    {
      title: stringResource.portfolio.label.intermediate,
      content: stringResource.portfolio.skills.intermediateSkills,
      icon: (
        <FontAwesomeIcon
          className='fa-sm skills__title__icon'
          icon={faLayerGroup}
          style={{ marginRight: '0.5em' }}
        />
      ),
    },
    {
      title: stringResource.portfolio.label.softskills,
      content: stringResource.portfolio.skills.softSkills,
      icon: (
        <FontAwesomeIcon
          className='fa-sm skills__title__icon'
          icon={faComment}
          style={{ marginRight: '0.5em' }}
        />
      ),
    },
  ];

  jobs.sort((a, b) => parseFloat(b.id) - parseFloat(a.id));

  return (
    <div className='content skills'>
      <MetaTags>
        <title>{stringResource.portfolio.skills.pagetitle}</title>
        <meta
          name='description'
          content={stringResource.portfolio.home.aboutmedescr}
        />
      </MetaTags>
      <div className='skills__download'>
        <Button
          label='Resumé'
          size='medium'
          onClick={downloadCV}
          primary={true}
        ></Button>
      </div>
      <div className='skills__overviewSection'>
        <PointOfIntrest props={listOfPOI} />
      </div>
      <div className='skills__title'>
        Work Experience &nbsp;{' '}
        <FontAwesomeIcon
          className='fa-sm skills__title__icon'
          icon={faLaptopCode}
          style={{ marginRight: '0.5em' }}
        />
      </div>

      <div className='skills__section'>
        <DetailsCard props={jobs.slice(0, 2)} />
      </div>

      <div className='skills__section'>
        <DetailsCard props={jobs.slice(2, 5)} />
      </div>

      <div className='skills__title'>
        Personal Projects &nbsp;{' '}
        <FontAwesomeIcon
          className='fa-sm skills__title__icon'
          icon={faLaptop}
          style={{ marginRight: '0.5em' }}
        />
      </div>

      <div className='skills__section'>
        <DetailsCard props={personalProjects.slice(0, 2)} />
      </div>

      <div className='skills__section'>
        <DetailsCard props={personalProjects.slice(2, 4)} />
      </div>

      <div className='skills__title'>
        Technical Skills &nbsp;{' '}
        <FontAwesomeIcon
          className='fa-sm skills__title__icon'
          icon={faCode}
          style={{ marginRight: '0.5em' }}
        />
      </div>

      <DevIcons
        props={stringResource.portfolio.skills.all.split(',')}
        className='skills__tech'
      />

      <div className='skills__title'>
        Education &nbsp;{' '}
        <FontAwesomeIcon
          className='fa-sm skills__title__icon'
          icon={faBook}
          color={'#e7e6dd'}
          style={{ marginRight: '0.5em' }}
        />
      </div>
      <div className='skills__education'>
        <div className='skills__educationDetails'>
          <DisplayCard props={academics} />
        </div>
        <div className='skills__certificationsDetails'>
          <DisplayCard props={certifications} />
        </div>
      </div>
    </div>
  );
}

export default Skills;
