import TodoPage from './client/components/Todo/TodoPage';
import ContactMe from './client/components/ContactMe/ContactMe';
import Login from './client/components/Authentication/Login/Login';
import ForgotPassword from './client/components/Authentication/ForgotPassword/ForgotPassword';
import EmailVerification from './client/components/Authentication/EmailVerification/EmailVerification';

import Home from './client/contents/Home/Home';
import Skills from './client/contents/Skills/Skills';
import Details from './client/contents/Skills/Details/Details';
import Pictures from './client/contents/Pictures/Pictures';
import Videos from './client/contents/Videos/Videos';
import Dashboard from './client/contents/Dashboard/Dashboard';
import Recipes from './client/contents/Recipes/Recipes';
import Store from './client/contents/Store/Store';

import UpdateProfile from './client/components/Authentication/UpdateProfile/UpdateProfile';
import SignUp from './client/components/Authentication/SignUp/SignUp';

export default [
  {
    path: '/',
    exact: true,
    name: 'Home',
    Component: Home,
    usesAuth: false,
    isPrivate: false,
  },
  {
    path: '/skills',
    name: 'Skills',
    Component: Skills,
    usesAuth: false,
    isPrivate: false,
  },
  {
    path: '/skills/:id',
    name: 'Details',
    Component: Details,
    usesAuth: false,
    isPrivate: false,
  },
  {
    path: '/pictures',
    name: 'Pictures',
    Component: Pictures,
    usesAuth: false,
    isPrivate: false,
  },
  {
    path: '/videos',
    name: 'Videos',
    Component: Videos,
    usesAuth: false,
    isPrivate: false,
  },
  {
    path: '/store',
    name: 'Store',
    Component: Store,
    usesAuth: false,
    isPrivate: false,
  },
  {
    path: '/contactme',
    name: 'Contact',
    Component: ContactMe,
    usesAuth: false,
    isPrivate: false,
  },
  {
    path: '/login',
    name: 'Login',
    Component: Login,
    usesAuth: true,
    isPrivate: false,
  },
  {
    path: '/signup',
    name: 'Sign Up',
    Component: SignUp,
    usesAuth: true,
    isPrivate: false,
  },
  {
    path: '/forgot-password',
    name: 'Forgot Password',
    Component: ForgotPassword,
    usesAuth: true,
    isPrivate: false,
  },
  {
    path: '/email-verification',
    name: 'Email Verification',
    Component: EmailVerification,
    usesAuth: true,
    isPrivate: false,
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    Component: Dashboard,
    usesAuth: true,
    isPrivate: true,
  },
  {
    path: '/update-profile',
    name: 'Update Profile',
    Component: UpdateProfile,
    usesAuth: true,
    isPrivate: true,
  },
  {
    path: '/todo',
    name: 'Todo',
    Component: TodoPage,
    usesAuth: true,
    isPrivate: true,
  },
  {
    path: '/recipes',
    name: 'Recipes',
    Component: Recipes,
    usesAuth: true,
    isPrivate: true,
  },
  /*   {
    path: '*',
    name: 'I am a teapot',
    Component: NotFound,
    usesAuth: false,
    isPrivate: false,
  }, */
];
