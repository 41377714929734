import React from 'react';
import { useAuth } from '../../contexts/AuthContext';
import { Link } from 'react-router-dom';
import stringResource from '../../resources/strings';
import Emoji from '../../components/Emoji/Emoji';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import './Dashboard.scss';
import MetaTags from 'react-meta-tags';

export default function Dashboard() {
  const { currentUser } = useAuth();
  return (
    <div className='content'>
      <MetaTags>
        <title>Dashboard</title>
      </MetaTags>
      <div className='dashboard-header'></div>
      <div className='dashboard'>
        <h1 className='dashboard__greeting'>
          {stringResource.portfolio.dashboard.greeting} &nbsp;
          <a href={`mailto: ${currentUser.email}`}>{currentUser.email}</a>{' '}
          &nbsp;
          <Emoji label='Celebration' symbol='🎉' />
        </h1>
        <div className='dashboard__information'>
          <div className='dashboard__information__item'>
            <FontAwesomeIcon
              className='fa-sm'
              icon={faInfoCircle}
              style={{ marginRight: '0.5em' }}
            />
            <strong>You made it! &nbsp;</strong>The login functionality is to
            demonstrate how{' '}
            <strong>accounts, authentication and authorisation</strong> work
            using &nbsp;
            <strong>Firebase</strong>.
          </div>
          <div className='dashboard__information__item'>
            The <strong>tools</strong> below are proof-of-concept and
            demonstrate the <strong>CRUD</strong> behaviour. &nbsp;
          </div>
        </div>
        <ol>
          <li>
            <Link className='dashboard__section' to='/todo'>
              <h3>
                {stringResource.portfolio.tools.todolist.name}{' '}
                <Emoji label='memo' symbol='📝' />
              </h3>
            </Link>
          </li>
          <li>
            <Link className='dashboard__section' to='/recipes'>
              <h3>
                Recipes <Emoji label='Paella' symbol='🥘' />
              </h3>
            </Link>
          </li>
        </ol>
      </div>
    </div>
  );
}
