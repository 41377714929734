import React from 'react';
import Button from '../Common/Button/Button';
import Input from '../Common/Input/Input';

const Form = ({ userInput, setUserInput, handleAdd }) => {
  return (
    <div className='todo__form'>
      <Input
        size='large'
        value={userInput}
        onChange={(e) => setUserInput(e.target.value)}
        placeholder={'Add item here'}
      />
      <Button
        label='Add'
        size='medium'
        primary={true}
        onClick={handleAdd}
      ></Button>
    </div>
  );
};

export default Form;
