import imagesResource from './client/resources/images';
import stringResource from './client/resources/strings';

export default [
  {
    id: 1,
    title: 'HILL OF BEANS',
    description: 'Digital Album',
    image: imagesResource.product.hob,
    price: 4,
    category: 'Music',
    ispostingincluded: false,
    url: stringResource.portfolio.hillofbeansyoutubelink,
  },

  {
    id: 2,
    title: 'Girl Who Likes Chocolate',
    description: 'Single',
    image: imagesResource.product.gwlc,
    price: 0.99,
    category: 'Music',
    ispostingincluded: false,
    url: stringResource.portfolio.gwlcyoutubelink,
  },
  {
    id: 3,
    title: 'Lonely Together',
    description: 'Single',
    image: imagesResource.product.lt,
    price: 0.99,
    category: 'Music',
    ispostingincluded: false,
    url: stringResource.portfolio.youtubelinkltsingle,
  },
  {
    id: 4,
    title: 'Hate Wing',
    description: 'Single',
    image: imagesResource.product.hw,
    price: 0.99,
    category: 'Music',
    ispostingincluded: false,
    url: stringResource.portfolio.youtubelinkhwsingle,
  },
  {
    id: 5,
    title: 'introspect',
    description: 'Single',
    image: imagesResource.product.introspect,
    price: 0.99,
    category: 'Music',
    ispostingincluded: false,
    url: stringResource.portfolio.introspectsingle,
  },
].reverse();
