import React from 'react'
import Ingredient from '../../components/Recipe/Ingredient'
import './IngredientList.scss'

export default function IngredientList({ingredients}) {
    const ingredientElements = ingredients.map((ingredient,key) => {return <Ingredient key={key} {...ingredient}/>})
    return (
        <div className='ingredient-grid'>
            {ingredientElements}
        </div>
    )
}
