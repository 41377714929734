import React from 'react';
import './Todo.scss';
import stringResource from '../../resources/strings';

const Header = ({ name }) => {
  const userName = name.split('@')[0];

  return (
    <div className='todo__header'>
      <div className='todo__userName'>
        {userName}&apos;s {stringResource.portfolio.tools.todolist.name}{' '}
      </div>
    </div>
  );
};

export default Header;
