import React, { useEffect } from 'react';
import listOfVideos from '../../../VideosList';
import './Videos.scss';

function Videos() {
  useEffect(() => {
    document.title = 'Videos';
  }, []);

  return (
    <div className='content videos'>
      {listOfVideos.map((video, key) => {
        return (
          <div className='videoWrapper' key={key}>
            <iframe
              src={video.url}
              title={video.tile}
              allowFullScreen
              frameBorder='0'
            />
          </div>
        );
      })}
    </div>
  );
}

export default Videos;
