import React, { useRef, useState } from 'react';
import './UpdateProfile.scss';
import { useAuth } from '../../../contexts/AuthContext';
import { Link, useHistory } from 'react-router-dom';
import Button from '../../Common/Button/Button';
import Input from '../../Common/Input/Input';
import MetaTags from 'react-meta-tags';

export default function UpdateProfile() {
  const { currentUser, updateEmail, updatePassword } = useAuth();
  const emailRef = useRef();
  const passwordRef = useRef();
  const passwordConfirmRef = useRef();
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  function handleSubmit(e) {
    e.preventDefault();
    setLoading(true);
    setError('');

    if (passwordRef.current.value !== passwordConfirmRef.current.value) {
      return setError('Passwords do not match');
    }

    const promises = [];
    if (emailRef.current.value !== currentUser.email) {
      promises.push(updateEmail(emailRef.current.value));
    }
    if (passwordRef.current.value) {
      promises.push(updatePassword(passwordRef.current.value));
    }

    Promise.all(promises)
      .then(() => {
        history.push('./dashboard');
      })
      .catch(() => {
        setError('Failed to update account');
      })
      .finally(() => {
        setLoading(false);
      });
  }

  return (
    <div className='content updateProfile'>
      <MetaTags>
        <title>Manage Profile</title>
      </MetaTags>
      <card>
        <body>
          <h2 className='updateProfile__title'>Update Profile</h2>
          {error && <alert variant='danger'> {error}</alert>}
        </body>

        <form onSubmit={handleSubmit} className='updateProfile__form'>
          <group id='email'>
            <label>Email </label>
            <Input
              size='large'
              type='email'
              ref={emailRef}
              required
              defaultValue={currentUser.email}
            />
          </group>
          <group id='password'>
            <label>Password </label>
            <Input
              size='large'
              type='password'
              ref={passwordRef}
              placeholder='Leave blank to keep the same'
            />
          </group>
          <group id='password-confirm'>
            <label>Password Confirmation</label>
            <Input
              size='large'
              type='password'
              ref={passwordConfirmRef}
              placeholder='Leave blank to keep the same'
            />
          </group>
          <Button
            disabled={loading}
            label='Update'
            size='medium'
            type='submit'
            primary={true}
          ></Button>
        </form>

        <div className='updateProfile__login'>
          <Link to='/dashboard'> Cancel</Link>
        </div>
      </card>
    </div>
  );
}
