import imagesResource from './client/resources/images';
import stringResource from './client/resources/strings';

export default [
  {
    id: stringResource.portfolio.skills.perosnalProjects.job1.id,
    title: stringResource.portfolio.skills.perosnalProjects.job1.title,
    where: stringResource.portfolio.skills.perosnalProjects.job1.where,
    from: stringResource.portfolio.skills.perosnalProjects.job1.from,
    to: stringResource.portfolio.skills.perosnalProjects.job1.to,
    details: stringResource.portfolio.skills.perosnalProjects.job1.details,
    techUsed: stringResource.portfolio.skills.perosnalProjects.job1.techUsed,
    website: stringResource.portfolio.skills.perosnalProjects.job1.website,
    imgsrc: imagesResource.skills.perosnalProjects.tcbPicture,
  },
  {
    id: stringResource.portfolio.skills.perosnalProjects.job2.id,
    title: stringResource.portfolio.skills.perosnalProjects.job2.title,
    where: stringResource.portfolio.skills.perosnalProjects.job2.where,
    from: stringResource.portfolio.skills.perosnalProjects.job2.from,
    to: stringResource.portfolio.skills.perosnalProjects.job2.to,
    details: stringResource.portfolio.skills.perosnalProjects.job2.details,
    techUsed: stringResource.portfolio.skills.perosnalProjects.job2.techUsed,
    website: stringResource.portfolio.skills.perosnalProjects.job2.website,
    imgsrc: imagesResource.skills.perosnalProjects.faeutero,
  },
];
