import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faSignInAlt } from '@fortawesome/free-solid-svg-icons';
import stringResource from '../../resources/strings';
import imagesResource from '../../resources/images';
import Social from '../Social/Social';
import './Footer.scss';

const Footer = () => {
  return (
    <div className='footer'>
      <div className='footer__logo'>
        <Link to='/'>
          <img src={imagesResource.home.logo}></img>
        </Link>
      </div>
      <ul className='footer__details'>
        <li className='footer__details__item'>
          <FontAwesomeIcon icon={faEnvelope} />
          <Link to='/contactme'>
            &nbsp; {stringResource.portfolio.label.contactme}&nbsp;
          </Link>
        </li>
        <li className='footer__details__item'>
          <FontAwesomeIcon icon={faSignInAlt} />
          <Link to='/login'>
            &nbsp; {stringResource.portfolio.label.logIn}&nbsp;
          </Link>{' '}
          |
          <Link to='/signup'>
            &nbsp; {stringResource.portfolio.label.signUp}
          </Link>
        </li>
        <hr />
        <li className='footer__details__item'>
          &copy;{new Date().getFullYear()} | {stringResource.portfolio.username}{' '}
          | All rights reserved | This website is built with&nbsp;
          <a
            href={stringResource.portfolio.reactjswebsite}
            target='_blank'
            rel='noreferrer'
          >
            ReactJS
          </a>
          {', '}
          <a
            href={stringResource.portfolio.netlifywebsite}
            target='_blank'
            rel='noreferrer'
          >
            Netlify{' '}
          </a>{' '}
          and
          <a
            href={stringResource.portfolio.firebasewebsite}
            target='_blank'
            rel='noreferrer'
          >
            {' '}
            Firebase.{' '}
          </a>
        </li>
      </ul>
      <Social />
    </div>
  );
};

export default Footer;
